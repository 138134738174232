.box {
    &.has-icon {
        img {
            max-width: 40px;
        }
        display: flex;
        flex-direction: column;
        p.typ-8 {
            flex-grow: 1;
        }
        h4 {
            min-height: 45px;
        }
    }

    &.simple {
        min-height: 55px;
        border-top: 1px solid rgba($color: $body-font-color, $alpha: .25);
        padding-top: 22px;
            padding-right: 40px;
        @include breakpoint(medium) {
            min-height: 150px;
        }
        &.compact {
            padding-top: 8.5px;
            padding-right: 0;
            margin-bottom: 2rem;
        }
        &.no-height {
            min-height: unset;
        }
        &.no-border {
            border: none;
        }
    }
}