// Single Services Page list

body.single-services {
    main ul li {
        font-size: 20px;
    }
}

body.single-post {
    .wp-block-embed {
        margin: 1rem auto;
        iframe {
            width: 100%;
        }
    }
    figure.wp-block-gallery.has-nested-images, figure.wp-block-image {
        margin: 1rem auto;
    }
    .article-hero {
        height: 100%;
        aspect-ratio: 3/2;
        background-size: contain;
    }
}