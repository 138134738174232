.hero-container {
    height: 100vh; 
    //background-image: url('../img/oceanking_hero_cover.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 6;
    position: relative;
    &.service {
        height: 842px;
    }
    &.generic {
        background-image: url('../img/Helix\ \(1\).svg');
        z-index: unset;
        padding-block: 85px 185px;
        &.contact {
            height: 670px;
            background-position: left 200px;
        }
        &.no-bg {
            background-image: none;
        }
    }
    &.service-1 {
        background-image: url('../img/Equipment Representation_big.jpg');
    }
    &.service-2 {
        background-image: url('../img/Intergrated After Sales_big.jpg');
    }
    &.service-3 {
        background-image: url('../img/Digitalization_big.jpg');
    }
    &.service-4 {
        background-image: url('../img/Retrofitting_big.jpg');
    }
    &.service-5 {
        background-image: url('../img/Contractors_big.jpg');
    }
    &.service-6 {
        background-image: url('../img/Industrial Systems_big.jpg');
    }
    &.service-7 {
        background-image: url('../img/Newbuildings_big.jpg');
    }
    &.service-8 {
        background-image: url('../img/Training_big.jpg');
    }
}

.hero {
    height: 100%;
    position: relative;
    padding-bottom: 60px;
    .service & {
        padding-top: 300px;
    }
}

.stickToElement-placeholder {
    position: absolute; 
    bottom:200px;
}

.hero-overlay {
    background: transparent linear-gradient(180deg, #001A6100 0%, #000C18 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}