// Typefaces
// ---------

@font-face {
    font-family: 'Aeonik Pro';
    src: url('../fonts/AeonikPro-Regular.woff2') format('woff2'),
         url('../fonts/AeonikPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik Pro';
    src: url('../fonts/AeonikPro-Medium.woff2') format('woff2'),
         url('../fonts/AeonikPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}