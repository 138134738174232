footer {
    z-index: 2;
    position: relative;
    &.main-footer {
        background-image: url('../img/pexels-elina-emurlaeva-10461974\ \(1\).jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 36px 0 20px 0;
        @include breakpoint(medium) {
            padding: 80px 0 30px 0;
        }
        @include breakpoint(xlarge) {
            padding: 140px 0 55px 0;
        }
    }
}