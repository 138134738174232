.tabsSwiper {
    margin-bottom: 40px;
    @include breakpoint(medium) {
        margin-bottom: 56px;
    }
    @include breakpoint(xlarge) {
        margin-bottom: 60px;
    }
    @include breakpoint(xxlarge) {
        margin-bottom: 80px;
    }
    .section-tabs {
        box-sizing: border-box;
        border: none;
        background: unset;
        .tabs-title {
            width: 158px;
            height: unset;
            a {
                height: 100%;
                padding: 10px 15px 0 20px;
                color: rgba($color: $body-font-color, $alpha: .15);
                // opacity: .15;
                border-top: 1px solid rgba($color: $body-font-color, $alpha: .15);
                @extend .typ-7;
                @extend .typ-medium;
                transition: color .25s ease;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 0%;
                    height: 1px;
                    background-color: $body-font-color;
                    transition: width .25s ease-out;
                }
                &[aria-selected='true'] {
                    // border-top: 1px solid $body-font-color;
                    background: unset;
                    // opacity: 1;
                    color: $body-font-color;
                    &:before {
                        width: 100%;
                    }
                }
                &:focus, &:active {
                    background: unset;
                }
            }
            &:first-child {
                a {
                    padding-left: 0;
                }
            }
            @include breakpoint(medium) {
                width: 145px;
            }
            @include breakpoint(xlarge) {
                flex: 1 1 0px;
            }
        }
    }
}

.services-tabs-content {
    // padding-inline: 80px 90px;
    .single-tab-content {
        @include breakpoint(medium) {
            padding-inline: 30px;
        }
        @include breakpoint(xlarge) {
            padding-inline: 50px;
        }
        @include breakpoint(xxlarge) {
            padding-inline: 90px;
        }
    }
    border: none;
    background: transparent;
    .tabs-panel {
        padding: 0;
        h3 {
            @extend .typ-light-blue;
            @extend .typ-medium;
            @extend .typ-3;
            @extend .typ-margin-v-s-m;
        }
        p {
            @extend .typ-margin-v-m;
        }
        .single-tab {
            img {
                height: 300px;
                object-fit: cover;
                object-position: top;
                @include breakpoint(xxlarge) {
                    height: 600px;
                }
            }
        }
    }
}

.dnaTabs {
    margin-bottom: 30px;
    @include breakpoint(medium) {
        padding-inline: 30px;
    }
    @include breakpoint(xlarge) {
        padding-inline: 50px;
        margin-bottom: 0px;
        
    }
    @include breakpoint(xxlarge) {
        padding-inline: 90px;
    }
    .dna-tabs {
        box-sizing: border-box;
        border: none;
        background: unset;
        display: flex;
        flex-direction: column;
        row-gap: 17px;
        .tabs-title {
            height: unset;

            @include breakpoint(small only) {

                &.is-active {
                    opacity: 0.4;
                }
                &:hover {
                    cursor: pointer;
                    opacity: 0.4;
                }
            }

            a {
                height: 100%;
                padding:  0;
                color: $body-font-color;
                opacity: .15;
                transition: opacity .25s ease;
                &[aria-selected='true'] {
                    background: unset;
                    opacity: 1;
                }
                &:focus, &:active {
                    background: unset;
                }
            }
        }
        &:after {
            display: none;
        }
    }
}

.dna-tabs-content {
    display: flex;
    flex-direction: column;
    border: none;
    background: transparent;
    @include breakpoint(medium) {
        padding-inline: 30px;
    }
    @include breakpoint(xlarge) {
        padding-inline: 50px;
    }
    @include breakpoint(xxlarge) {
        padding-inline: 90px;
    }
    .tabs-panel {
        padding: 0;
        display: none;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
        p {
            &:last-child{
                margin-bottom: 0;
            }
        }
        &.is-active {
            display: block;
        }
        @include breakpoint(xlarge) {
            display: block;
            &:last-child {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
            }
        }
    }
}