.icon {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 18px;
    &.linkedin-icon {
        background-image: url('../img/Linkedin-Icon.svg');
        width: 18px;
    }
    &.facebook-icon {
        background-image: url('../img/Facebook-Icon.svg');
        width: 18px;
    }
    &.youtube-icon {
        background-image: url('../img/Youtube-Icon.svg');
        width: 25.6px;
    }
}