.main-header {
    width: 100%;
    &.dynamic {
        position:absolute; 
        top: 0; 
        left: 0; 
    }
}

.header-container {
    background-color: $white;
    z-index: 0;
    transition: background-color .25s ease;
    border-bottom: 1px solid $bright_gray;
    z-index: 5;
    .dynamic & {
        z-index: 7;
    }
    .is-light & {
        background-color: transparent;
        border-bottom: none;
    }
    &.is-stuck {
        z-index: 5;
        background-color: $white;
    }
}

.header-content {
    box-sizing: content-box;
    .is-light & {
        .logo-holder {
            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(203deg) brightness(108%) contrast(101%);
            }
        }
    }
    .logo-holder, .logo-mobile--holder {
        padding-block: 28.55px;
        img {
            width: 228px;
            @include breakpoint(xlarge) {
                width: 185px;
            }
            @include breakpoint(xxlarge) {
                width: 306px;
            }
        }
    }
}

.mobile-logo--holder {
    padding-block: 28.55px;
    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(203deg) brightness(108%) contrast(101%);
        width: 228px;
        @include breakpoint(xlarge) {
            width: 185px;
        }
        @include breakpoint(xxlarge) {
            width: 306px;
        }
    }
}