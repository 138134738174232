.styled-list {
    margin: 0;
    list-style: none;
    &.bordered {
        li {
            @extend .typ-4;
            padding-block: 16px;
            @include breakpoint(xlarge) {
                padding-block: 25px;
            }
            border-top: 1px solid rgba($color: $body-font-color, $alpha: .25);
            &:last-child {
                border-bottom: 1px solid rgba($color: $body-font-color, $alpha: .25);
            }
        }
    }
    &.vertical {
        display: flex;
        flex-direction: column;
        // padding-inline: 15px;
        @include breakpoint(medium) {
            padding-inline: 30px;
        }
        @include breakpoint(xlarge) {
            padding-inline: 50px;
        }
        @include breakpoint(xxlarge) {
            padding-inline: 90px;
        }
        li {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            p {
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &:last-child {
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
            }
        }
    }
    &.stacked {
        display: flex;
        flex-direction: column;
        &.light {
            li {
                border-bottom: 1px solid rgba($color: $white, $alpha: .6);
                footer & {
                    border-bottom: 1px solid rgba($color: $white, $alpha: .16);
                }
            }
        }
        li {
            padding-block: 15px;
            @include breakpoint(medium) {
                padding-block: 20px;
            }
            @include breakpoint(large) {
                padding-block: 30px;
            }
            border-bottom: 1px solid rgba($color: $body-font-color, $alpha: .25); 
            
            &:first-child {
                padding-block-start: 0px;
            }
            &:last-child {
                padding-block-end: 0px;
                border: none;
                footer & {
                    border: none;
                }
            }
        }
        &.simple {
            row-gap: 18px;
            li {
                padding-block: 3px;
                border-bottom: 1px solid rgba($color: $white, $alpha: .6); 
                footer & {
                    border-bottom-color: rgba($color: $white, $alpha: .16);
                }
                &:last-child {
                    border-bottom: 1px solid rgba($color: $white, $alpha: .25); 
                }
            }
        }
        
    }
    &.column-gap-s {
        column-gap: 25.5px;
    }
}