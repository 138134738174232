.valuesSwiper {
    &:hover {
        cursor: grab;
    }
    .section-values {
        box-sizing: border-box;
        .value-item {
            // width: fit-content;
            width: 285px;
            > * {
                padding-right: 65px;
            }
            @include breakpoint(xxlarge) {
                width: 485px;
                > * {
                    padding-right: 65px;
                }
            }
        }
    }
}
