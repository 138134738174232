$colors: #6AC8EB, #1AACDE,#4FBBE0,#63C8EA,#98D9ED,#6FC0DE,#0099D5,#16A0CE ;

@each $color in $colors {
    $i: index($colors, $color);
    .partners-accordion .accordion-item:nth-child(#{$i}) a.single-cat {
        background-color: $color;
    }
}

.partners-accordion {
    
    .accordion-item {

        a.accordion-title.archive {
            &:before {
                background-image: none!important;
            }
        }
        a.single-cat {
            border: none;
            padding: 25px;
            border-radius: 4px!important;
            @extend .typ-white;
            @extend .typ-2-3;
            
            &:hover , &:focus {
                @extend .typ-white;
            }
            &:before {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMzk4NyIgZGF0YS1uYW1lPSJHcm91cCAzOTg3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTY1NCAtMjIzOCkiPgogICAgPGcgaWQ9Ikdyb3VwXzM0MDIiIGRhdGEtbmFtZT0iR3JvdXAgMzQwMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMzMSA3NjQuMTE4KSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTciIGRhdGEtbmFtZT0iUGF0aCAyMzk3IiBkPSJNMTI2MDEuOTU4LDE1MDAuMzMxaDIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIyNjkuOTU4IC02LjQ1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogICAgPC9nPgogICAgPGcgaWQ9Ikdyb3VwXzM3MzYiIGRhdGEtbmFtZT0iR3JvdXAgMzczNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzE2Ny44ODIgMTkxNSkgcm90YXRlKDkwKSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTctMiIgZGF0YS1uYW1lPSJQYXRoIDIzOTciIGQ9Ik0xMjYwMS45NTgsMTUwMC4zMzFoMjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjI2OS45NTggLTYuNDUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8L2c+CiAgICA8ZyBpZD0iRWxsaXBzZV8yNTUiIGRhdGEtbmFtZT0iRWxsaXBzZSAyNTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NTQgMjIzOCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiPgogICAgICA8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxjaXJjbGUgY3g9IjIwIiBjeT0iMjAiIHI9IjE5LjI1IiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 40px;
                height: 40px;
                top: calc(50% - 20px);
                margin-top: 0;
                right: 25px;
                transition: transform .25s ease;
            }
            &[aria-expanded="true"] {
                &:before {
                    transform: rotate(180deg);
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMzk4NyIgZGF0YS1uYW1lPSJHcm91cCAzOTg3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTY1NCAtMjIzOCkiPgogICAgPGcgaWQ9Ikdyb3VwXzM0MDIiIGRhdGEtbmFtZT0iR3JvdXAgMzQwMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMzMSA3NjQuMTE4KSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTciIGRhdGEtbmFtZT0iUGF0aCAyMzk3IiBkPSJNMTI2MDEuOTU4LDE1MDAuMzMxaDIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIyNjkuOTU4IC02LjQ1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogICAgPC9nPgogICAgPGcgaWQ9Ikdyb3VwXzM3MzYiIGRhdGEtbmFtZT0iR3JvdXAgMzczNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzE2Ny44ODIgMTkxNSkgcm90YXRlKDkwKSIgb3BhY2l0eT0iMCI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTctMiIgZGF0YS1uYW1lPSJQYXRoIDIzOTciIGQ9Ik0xMjYwMS45NTgsMTUwMC4zMzFoMjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjI2OS45NTggLTYuNDUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8L2c+CiAgICA8ZyBpZD0iRWxsaXBzZV8yNTUiIGRhdGEtbmFtZT0iRWxsaXBzZSAyNTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NTQgMjIzOCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiPgogICAgICA8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxjaXJjbGUgY3g9IjIwIiBjeT0iMjAiIHI9IjE5LjI1IiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K");
                }
            }

            &.no-style {
                padding: 0;
                background: none;
                background-color: #fff;
                color: #011C3C;
                font-size: rem-calc(14);
                opacity: 0.8;
                &:before {
                    content: none;
                }
                &:hover {
                    color: #011C3C;
                    opacity: 1;
                    &:after {
                        transform: translateX(5px);
                    }
                }
            }
            @include breakpoint(small only) {
                padding-right: 90px;
                font-size: 18px!important;
                &:before {
                    transform: scale(.5);
                }
                &[aria-expanded="true"] {
                    &:before {
                        transform: rotate(180deg) scale(.5);
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMzk4NyIgZGF0YS1uYW1lPSJHcm91cCAzOTg3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTY1NCAtMjIzOCkiPgogICAgPGcgaWQ9Ikdyb3VwXzM0MDIiIGRhdGEtbmFtZT0iR3JvdXAgMzQwMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMzMSA3NjQuMTE4KSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTciIGRhdGEtbmFtZT0iUGF0aCAyMzk3IiBkPSJNMTI2MDEuOTU4LDE1MDAuMzMxaDIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIyNjkuOTU4IC02LjQ1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgogICAgPC9nPgogICAgPGcgaWQ9Ikdyb3VwXzM3MzYiIGRhdGEtbmFtZT0iR3JvdXAgMzczNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzE2Ny44ODIgMTkxNSkgcm90YXRlKDkwKSIgb3BhY2l0eT0iMCI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzIzOTctMiIgZGF0YS1uYW1lPSJQYXRoIDIzOTciIGQ9Ik0xMjYwMS45NTgsMTUwMC4zMzFoMjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjI2OS45NTggLTYuNDUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8L2c+CiAgICA8ZyBpZD0iRWxsaXBzZV8yNTUiIGRhdGEtbmFtZT0iRWxsaXBzZSAyNTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NTQgMjIzOCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiPgogICAgICA8Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxjaXJjbGUgY3g9IjIwIiBjeT0iMjAiIHI9IjE5LjI1IiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K");
                    }
                }
            }
        }
    }
    .accordion-content {
        padding: 50px 0 0 0;
        border: none;
        background-color: unset;
        color: unset;
        margin-bottom: 2rem;
    }
    .partner-content {
        position: relative;
        .partner-desc {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            transform: translateY(-10px);
            transition: opacity .45s ease, transform .45s ease;
        }
        .partner-logo {
            opacity: 1;
            visibility: visible;
            transition: opacity .45s ease;
        }
    }
    .box {
        > * {
            cursor: default;
        }
        .partner-content {
            &.show-desc {
                .partner-desc {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0px);
                }
                .partner-logo {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    .partner-logo {
        img {
            max-height: 70px;
        }
    }
}