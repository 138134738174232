.highlight {
    min-height: 520px;
    padding: 62px 20px;    
    display: flex;
    align-items: flex-end;
    position: relative;
    .highlight-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent linear-gradient(180deg, #001A6100 0%, #000C18 100%) 0% 0% no-repeat padding-box;
    }
    hgroup {
        position: relative;
    }
    &.our-people {
        background-image: url('../img/Section\ Photo.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.new-ship-sales {
        background-image: url('../img/Image\ &\ Type\ Section.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.focus {
        padding: 50px 20px;
        @include breakpoint(xlarge) {
            padding: 152px 0;
        }
    }
    @include breakpoint(xlarge) {
        min-height: 800px;
        padding: 91px 152px;
    }
}

.focus-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    .highlight {
        position: unset;
        min-height: 650px;
        @include breakpoint(medium) {
            min-height: 1000px;
        }
    }
    .highlight-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent linear-gradient(180deg, #001A6100 0%, #000C18 100%) 0% 0% no-repeat padding-box;
    }
    &.who-we-are {
        //background-image: url('../img/Image.jpg');
    }
    &.what-we-do {
        background-image: url('../img/what-we-do-section-highlight-image.jpg');
    }
    &.article-hero {
        .highlight {
            position: unset;
            min-height: 450px;
            @include breakpoint(medium) {
                min-height: 800px;
            }
        }
    }
    &.video-section {
        overflow: hidden;
        .highlight {
            min-height: 550px;
            @include breakpoint(medium) {
                min-height: 617px;
            }
            &.home {
                min-height: 510px;
            }
        }
    }
}

.intermission-photo {
    background-image: url('../img/Image-intermission.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.who-we-are--highlight {
    overflow: hidden;
}