.main-navigation.dropdown.menu {
    box-sizing: content-box;
}

/* Add active element in single news page*/
body.single-post .main-navigation.dropdown.menu li.news {
    a {
        padding-block: 40px;
        padding-inline: 0;
        color: $body-font-color;
        @extend .typ-9;
        background: unset;
        &::before {
            width: 100%;
            opacity: 1;
        }
    }
}

.main-navigation.dropdown.menu, .anchor-navigation.menu {
    margin: 0;
    list-style: none;
    column-gap: 35px;
    margin-right: 35px;
    position: unset;
    > li {
        a {
            padding-block: 40px;
            padding-inline: 0;
            color: $body-font-color;
            @extend .typ-9;
        }
        &:not(.is-dropdown-submenu-parent) {
            a {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 35px;
                    width: 15%;
                    height: 1px;
                    background-color: $body-font-color;
                    opacity: 0;
                    transition: opacity .25s ease, width .25s ease;
                }
                &:hover {
                    &:before {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
        }
        &.active {
            a {
                background: unset;
                &::before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
        &.is-dropdown-submenu-parent, &.has-arrow {
            position: unset;
            > a {
                padding-right: .8rem;
                &:after {
                    content:'';
                    position: absolute;
                    top: 50%;
                    display: block;
                    right: 5px;
                    left: auto;
                    margin-top: -3px;
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii0zMDMuMyAzOTIuNSAxMi44IDguMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtMzAzLjMgMzkyLjUgMTIuOCA4LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtvcGFjaXR5OjAuNjtmaWxsOiMwMTFDM0M7ZW5hYmxlLWJhY2tncm91bmQ6bmV3ICAgIDt9DQo8L3N0eWxlPg0KPHBhdGggaWQ9Ikljb25faW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgY2xhc3M9InN0MCIgZD0iTS0zMDIuMiwzOTIuNWwtMS4xLDEuM2w2LjQsN2w2LjQtN2wtMS4xLTEuMmwtNS4zLDUuNkwtMzAyLjIsMzkyLjV6Ii8+DQo8L3N2Zz4NCg==");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    border: none;
                    width: 13.47px;
                    height: 7.95px;
                    transition: transform .25s ease;
                }
            }
            &.is-active {
                a {
                    &:after {
                        transform: rotate(-180deg);
                    }
                }
            }
            .submenu.is-dropdown-submenu {
                width: 100vw;
                left: -20px!important;
                list-style: none;
                padding-block: 20px;
                z-index: 7;
                min-height: 230px;
                @include breakpoint (medium) {
                    left: -40px!important;
                }
                @include breakpoint (xxlarge) {
                    left: -60px!important;
                }
                border: 0!important;
                margin: 0px;
                display: block;
                box-sizing: border-box;
                opacity: 0;
                visibility: hidden;
                transition: opacity .25s ease, visibility .25s ease;
                a {
                    padding: 0;
                }
                &.js-dropdown-active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &.has-arrow {
            a {
                padding-right: 1.8rem;
                @extend .typ-light--alt;
                transition: all .25s ease;
                &:before {
                    display: none;
                }
                &:hover {
                    color: $body-font-color;
                    &:after {
                        transform: translateY(2px);
                    }
                }
            }
        }
    }
    .is-light & {
        > li {
            a {
                color: $white;
                &:active, &:focus {
                    color: $white;
                }
            }
            &:not(.is-dropdown-submenu-parent) {
                a {
                    &:before {
                        background-color: $white;
                    }
                }
            }
            &.is-dropdown-submenu-parent {
                a {
                    &:after {
                        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii0zMDMuMyAzOTIuNSAxMi44IDguMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtMzAzLjMgMzkyLjUgMTIuOCA4LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtvcGFjaXR5OjAuNjtmaWxsOiNGRkZGRkY7ZW5hYmxlLWJhY2tncm91bmQ6bmV3ICAgIDt9DQo8L3N0eWxlPg0KPHBhdGggaWQ9Ikljb25faW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgY2xhc3M9InN0MCIgZD0iTS0zMDIuMiwzOTIuNWwtMS4xLDEuM2w2LjQsN2w2LjQtN2wtMS4xLTEuMmwtNS4zLDUuNkwtMzAyLjIsMzkyLjV6Ii8+DQo8L3N2Zz4NCg==");
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1441px) and (max-width: 1500px) {
        column-gap: 25px;
        margin-right: 25px;
    }
}

.tags {
    position: relative;
    z-index: 1;
    width: fit-content;
    margin-top: 100px;
    margin-bottom: 50px;
    nav {
        margin-inline: 20px;
    }
    .tags-navigation.dropdown.menu {
        >li {
            a {
                padding-inline: 0;
                @extend .typ-light;
                padding-right: 1.8rem;
                @extend .typ-8;
                @extend .typ-medium;
                transition: color .25s ease;
                &:hover {
                    color: $body-font-color;
                }
            }
            ul {
                border: none;
                
                li {
                    a {
                        padding: .4rem 0; 
                    }
                }
            }
            &.is-dropdown-submenu-parent {
                > a {
                    &:hover {
                        @extend .typ-light;
                    }
                    &:after {
                        content:'';
                        position: absolute;
                        top: 50%;
                        display: block;
                        right: 5px;
                        left: auto;
                        margin-top: -3px;
                        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii0zMDMuMyAzOTIuNSAxMi44IDguMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtMzAzLjMgMzkyLjUgMTIuOCA4LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtvcGFjaXR5OjAuNjtmaWxsOiMwMTFDM0M7ZW5hYmxlLWJhY2tncm91bmQ6bmV3ICAgIDt9DQo8L3N0eWxlPg0KPHBhdGggaWQ9Ikljb25faW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgY2xhc3M9InN0MCIgZD0iTS0zMDIuMiwzOTIuNWwtMS4xLDEuM2w2LjQsN2w2LjQtN2wtMS4xLTEuMmwtNS4zLDUuNkwtMzAyLjIsMzkyLjV6Ii8+DQo8L3N2Zz4NCg==");
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        border: none;
                        width: 13.47px;
                        height: 7.95px;
                        opacity: .7;
                        transition: transform .25s ease;
                    }
                }
            }
            &.is-active {
                a {
                    &:after {
                        transform: rotate(-180deg);
                    }
                }
                ul {
                    display: flex;
                    flex-direction: row;
                    column-gap: 10px;
                }
            }
        }
    }
    @include breakpoint(medium) {
        nav {
            margin-inline: 40px;
        }
    }
    @include breakpoint(xlarge) {
        top: 177px;
        left: 40px;
        margin-top: unset;
        margin-bottom: unset;
        nav {
            margin-inline: unset;
            margin-top: unset;
            margin-bottom: unset;
            .tags-navigation.dropdown.menu {
                >li {
                    &.is-active {
                        ul {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    @include breakpoint(xxlarge) {
        position: sticky;
        top: 447px;
        left: 60px;
        
    }
}

// mobile trigger
.mobile-menu {
    position: fixed;
    height: 100%;
    top: 0px;
    z-index: 9;
    .mobile-menu--trigger {
        position: relative;
        top: 30px;
        right: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 50%;
        background-color: $absolute_zero;
        transition: transform .25s ease;
        .dots {
            position: relative;
            width: 100%;
            height: 100%;
            .dot {
                width: 5px;
                height: 5px;
                background-color: $white;
                border-radius: 5px;
                position: absolute;
                top: calc(50% - 2.5px);
                left: 0;
                &:first-child {
                    transform: translateX(0px);
                }
                &:nth-child(2) {
                    opacity: 1;
                    transform: translateX(9.5px);
                }
                &:last-child {
                    transform: translateX(19px);
                }
            }
        }
        
    }
    &.is-light {
        .mobile-menu--trigger {
            background-color: $white;
            .dot {
                background-color: $absolute_zero;
            }
        }
    }
}

.mobile-menu--trigger {
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    background-color: $absolute_zero;
    // transition: all .25s ease;
    @include breakpoint (medium) {
        right: 40px;
    }
    @include breakpoint (xxlarge) {
        right: 60px;
    }
    .dots {
        position: relative;
        width: 100%;
        height: 100%;
        .dot {
            width: 5px;
            height: 5px;
            background-color: $white;
            border-radius: 5px;
            position: absolute;
            top: calc(50% - 2.5px);
            left: 0;
            &:first-child {
                transform: translateX(0px);
            }
            &:nth-child(2) {
                opacity: 1;
                transform: translateX(9.5px);
            }
            &:last-child {
                transform: translateX(19px);
            }
        }
    }
    &.is-light {
        background-color: $white;
        .dot {
            background-color: $absolute_zero;
        }
    }
    &.stuckTrigger {
        position: fixed;
        z-index: 6;
    }
    &.active {
        z-index: 10;
        background-color: $white;
        .dot {
            background-color: $absolute_zero;
            &:nth-child(2) {
                opacity: 0;
            }
            &:first-child {
                animation-duration: .5s;
                animation-name: moveFirstDot;
                animation-fill-mode: forwards;
            }
            &:last-child {
                animation-duration: .5s;
                animation-name: moveLastDot;
                animation-fill-mode: forwards;
            }
        }
    }
}

#mobile-menu:checked ~ .mobile-menu--trigger {
    background-color: $white;
    .dot {
        background-color: $absolute_zero;
        &:nth-child(2) {
            opacity: 0;
        }
        &:first-child {
            animation-duration: .5s;
            animation-name: moveFirstDot;
            animation-fill-mode: forwards;
        }
        &:last-child {
            animation-duration: .5s;
            animation-name: moveLastDot;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes moveFirstDot {
    0% {
        transform: translateX(0px);
    }
    33% {
        transform: translateX(9.5px);
        width: 5px;
    }
    66% {
        transform: translateX(0);
        width: 24px;
    }
    100% {
        transform: translateX(0px) rotateZ(-45deg);
        width: 24px;
    }
}

@keyframes moveLastDot {
    0% {
        transform: translateX(19px);
    }
    33% {
        transform: translateX(9.5px);
        width: 5px;
    }
    66% {
        transform: translateX(0px);
        width: 24px;
    }
    100% {
        transform: translateX(0px) rotateZ(45deg);
        width: 24px;
    }
}

.offcanvas-menu {
    width: 100vw;
    height: 100%;
    height: -webkit-fill-available;
    display: flex;
    position: absolute;
    left: -100vw;
    top: 0;
    background-color: $absolute_zero;
    transition: transform .25s ease;
    .offcanvas-container {
        display: flex;
        padding-bottom: 20px;
        width: 100%;
        .offcanvas-grid {
            height: 100%;
            width: 100%;
            .mobile-navigation {
                list-style: none;
                margin: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                row-gap: 12px;
                @extend .typ-margin-v-xl;
                li {
                    a {
                        @extend .typ-3;
                        @extend .typ-white;
                        @extend .typ-medium;
                    }
                    &.active {
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .mobile-menu--footer {
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding-bottom: 40px;
            }
        }
    }
}

#mobile-menu:checked ~ .offcanvas-menu {
    transform: translateX(100vw);
}

.company-list {
    .company-2 {
        width: 152px;
        height: 28px;
        background-repeat: no-repeat;
        background-image: url('../img/ock nss logotype_blue.svg');
        background-size: contain;
        transition: background-image .25s ease;
        &.active {
            background-image: url('../img/ock nss logotype_black.svg');
        }
    }
    .company-1 {
        width: 152px;
        height: 28px;
        background-repeat: no-repeat;
        background-image: url('../img/ock engineering logotype_blue.svg');
        background-size: contain;
        transition: background-image .25s ease;
        &.active {
            background-image: url('../img/ock engineering logotype_black.svg');
        }
    }
}