/*---------------------
| TYPOGRAPHY HELPERS  |
---------------------*/

// Type Sizes
// ----------

// used on: hero
.typ-1 {
    font-size: 40px;
    line-height: 42px;
    @include breakpoint (medium) {
        font-size: 62px;
        line-height: 62px;
    }
    @include breakpoint (xlarge) {
        font-size: 75px;
        line-height: 70px;
    }
    @include breakpoint (xxlarge) {
        font-size: 100px;
        line-height: 104px;
    }
}

.typ-2 {
    font-size: 35px;
    line-height: 33px;
    @include breakpoint(medium) {
        font-size: 40px;
        line-height: 42px;
    }
    @include breakpoint(xxlarge) {
        font-size: 70px;
        line-height: 67px;
    }
}

.typ-2-3 {
    font-size: 25px;
    line-height: 35px;
    @include breakpoint(medium) {
        font-size: 20px;
        line-height: 38px;
    }
    @include breakpoint(xxlarge) {
        font-size: 30px;
        line-height: 34px;
    }
}

.typ-3 {
    font-size: 29px;
    line-height: 33px;
    @include breakpoint(xlarge) {
        font-size: 30px;
        line-height: 34px;
    }
    @include breakpoint(xxlarge) {
        font-size: 50px;
        line-height: 52px;
    }
}

.typ-3-4 {
    font-size: 20px;
    line-height: 25px;
    @include breakpoint(xlarge) {
        font-size: 30px;
        line-height: 34px;
    }
}

.typ-4 {
    font-size: 22px;/*18*/
    line-height: 24px;
    @include breakpoint(xlarge) {
        font-size: 15px;
        line-height: 26px;
        #values & {
            font-size: 24px;/*18*/
        }
    }
    @include breakpoint(xxlarge) {
        font-size: 30px;
        line-height: 34px;
    }
}

.typ-4-5-1 {
    font-size: 18px;
    line-height: 23px;
    @include breakpoint(xxlarge) {
        font-size: 40px;
        line-height: 44px;
    }
}

.typ-4-5 {
    font-size: 24px;
    line-height: 24px;
}

.typ-5 {
    font-size: 20px;
    line-height: 22px;
    @include breakpoint(medium) {
        font-size: 17px;
        line-height: 19px;
    }
    @include breakpoint(xxlarge) {
        font-size: 22px;
        line-height: 25px;
    }
}

.typ-5-6 {
    font-size: 18px;
    line-height: 22px;
    @include breakpoint(xxlarge) {
        font-size: 30px;
        line-height: 35px;
    }
}

// used on: ctas
.typ-6 {
    font-size: 18px;
    line-height: 20px;
    @include breakpoint(xlarge) {
        font-size: 16px;
        line-height: 21px;
    }
    @include breakpoint(xxlarge) {
        font-size: 20px;
        line-height: 27px;
    }
}

.typ-7 {
    font-size: 15px;
    line-height: 17px;
    @include breakpoint(medium) {
        font-size: 13px;
        line-height: 13px;
    }
    @include breakpoint(xxlarge) {
        font-size: 19px;
        line-height: 22px;
    }
}

.typ-8 {
    font-size: 16px;
    line-height: 19px;
    @include breakpoint(xlarge) {
        font-size: 14px;
        line-height: 17px;
    }
    @include breakpoint(xxlarge) {
        font-size: 18px;
        line-height: 22px;
    }
}

.typ-8-9 {
    font-size: 18px;
    line-height: 22px;
    @include breakpoint(medium) {
        font-size: 15px;
        line-height: 17px;
    }
    @include breakpoint(large) {
        font-size: 14px;
        line-height: 17px;
    }
    @include breakpoint(xxlarge) {
        font-size: 18px;
        line-height: 21px;
    }
}

.typ-9 {
    font-size: 16px;/*14*/
    line-height: 19px;
    @include breakpoint (xxlarge) {
        font-size: 19px;/*17*/
        line-height: 27px;
    }
}

.typ-10 {
    font-size: 14px;
    line-height: 14px;
}

.typ-11 {
    font-size: 11px;
    line-height: 11px;
}

// Type Line Heights
// -----------------

.typ-lh-regular {
    line-height: 1rem;
}

.typ-lh-s {
    line-height: 21px;
}

.typ-lh-comfort {
    line-height: 1.9rem;
}

// Type Weights
// ------------

.typ-medium {
    font-weight: 500;
}

.typ-regular {
    font-weight: 400;
}

// Type Containers
// ---------------

.cont-xl {
    max-width: 970px;
    @include breakpoint (medium) {
        max-width: 515px;
    }
    @include breakpoint (xlarge) {
        max-width: 580px;
    }
    @include breakpoint (xxlarge) {
        max-width: 970px;
    }
}

.cont-l {
    max-width: 335px;
    @include breakpoint (medium) {
        max-width: 515px;
    }
    @include breakpoint (xlarge) {
        max-width: 635px;
    }
    @include breakpoint (xxlarge) {
        max-width: 915px;
    }
}

.cont-ml {
    max-width: 500px;
}

.cont-ms {
    max-width: 435px;
}

.cont-m {
    max-width: 265px;
    @include breakpoint(xxlarge) {
        max-width: 333px
    }
}

// Type Colors
// -----------

.typ-white {
    color: $white;
    a {
        color: $white;
    }
}

.typ-light-blue {
    color: $absolute_zero;
    a {
        color: $absolute_zero;
    }
}

.typ-blue {
    color: $body-font-color;
    a {
        color: $body-font-color;
    }
}

.typ-light {
    color: rgba($color: $body-font-color, $alpha: .38);
    a {
        color: rgba($color: $body-font-color, $alpha: .38);
    }
}

.typ-light--alt {
    color: rgba($color: $body-font-color, $alpha: .5);
    a {
        color: rgba($color: $body-font-color, $alpha: .5);
    }
}

.typ-transparent {
    color: rgba($color: $white, $alpha: .38);
    a {
        color: rgba($color: $white, $alpha: .38);
    }
}

// rows

.row-gap-m {
    row-gap: 30px;
}

// Inline Block Links
// ------------------
.ib {
    display: inline-block;
}

// Type Margins
// ------------

.typ-margin-v-xs {
    margin-bottom: 6px;
}

.typ-margin-v-s {
    margin-bottom: 15px;
}

.typ-margin-v-s-m {
    margin-bottom: 20px;
    @include breakpoint (xlarge) {
        margin-bottom: 35px;
    }
}

.typ-margin-v-m {
    margin-bottom: 30px;
    @include breakpoint (xlarge) {
        margin-bottom: 25px;
    }
}

.typ-margin-v-l {
    margin-bottom: 35px;
    @include breakpoint (xlarge) {
        margin-bottom: 50px;
    }
}

.typ-margin-v-l-xl {
    margin-bottom: 55px;
    @include breakpoint (xlarge) {
        margin-bottom: 70px;
    }
}

.typ-margin-v-xl {
    margin-bottom: 65px;
    @include breakpoint (xlarge) {
        margin-bottom: 95px;
    }
}

.margin-top-tiny {
    margin-top: 14.7px;
}

.margin-top-small {
    margin-top: 16px;
}

/*---------------------
|   SECTION MARGINS   |
---------------------*/


.section-margin-top-xs {
    margin-top: 40px;
}

.section-margin-bottom-xs {
    margin-bottom: 40px;
}

.section-margin-top-s {
    margin-top: 50px;
}

.section-margin-bottom-s {
    margin-bottom: 50px;
}

.section-margin-top-m {
    margin-top: 50px;
    @include breakpoint(xlarge) {
        margin-top: 100px;
    }
}

.section-margin-bottom-m {
    margin-bottom: 50px;
    @include breakpoint(xlarge) {
        margin-bottom: 100px;
    }
}

.section-margin-top-l {
    margin-top: 75px;
    @include breakpoint(xlarge) {
        margin-top: 150px;
    }
}

.section-margin-bottom-l {
    margin-bottom: 75px;
    @include breakpoint(xlarge) {
        margin-bottom: 150px;
    }
}

.section-margin-top-xl {
    margin-top: 80px;
    @include breakpoint(medium) {
        margin-top: 96px;
    }
    @include breakpoint(large) {
        margin-top: 200px;
    }
}

.section-margin-bottom-xl {
    margin-bottom: 80px;
    @include breakpoint(medium) {
        margin-bottom: 96px;
    }
    @include breakpoint(large) {
        margin-bottom: 200px;
    }
}

.section-margin-top-3xl {
    margin-top: 100px;
    @include breakpoint(xxlarge) {
        margin-top: 300px;
    }
}

.section-margin-top-3xl-middle {
    margin-top: 100px;
    @include breakpoint(xxlarge) {
        margin-top: 150px;
    }
}

.section-margin-bottom-3xl {
    margin-bottom: 100px;
    @include breakpoint(xxlarge) {
        margin-bottom: 300px;
    }
}

.section-margin-top-4xl {
    margin-top: 150px;
    @include breakpoint(xxlarge) {
        margin-top: 400px;
    }
}

.section-margin-bottom-4xl {
    margin-bottom: 150px;
    @include breakpoint(xxlarge) {
        margin-bottom: 400px;
    }
}

/*------------------------
|   MARGINS ONLY SMALL   |
------------------------*/
@include breakpoint(small only) {
    .section-margin-top-tiny--only-small {
        margin-top: 35px;
    }

    .section-margin-bottom-tiny--only-small {
        margin-bottom: 35px;
    }

    .section-margin-top-s--only-small {
        margin-top: 50px;
    }

    .section-margin-bottom-s--only-small {
        margin-bottom: 50px;
    }

    .section-margin-top-sm--only-small {
        margin-top: 80px;
    }

    .section-margin-bottom-sm--only-small {
        margin-bottom: 80px;
    }

    .section-margin-top-m--only-small {
        margin-top: 100px;
    }

    .section-margin-bottom-m--only-small {
        margin-bottom: 100px;
    }

    .section-margin-top-l--only-small {
        margin-top: 150px;
    }

    .section-margin-bottom-l--only-small {
        margin-bottom: 150px;
    }

    .section-margin-top-xl--only-small {
        margin-top: 200px;
    }

    .section-margin-bottom-xl--only-small {
        margin-bottom: 200px;
    }

    .section-margin-top-3xl--only-small {
        margin-top: 300px;
    }

    .section-margin-bottom-3xl--only-small {
        margin-bottom: 300px;
    }

    .section-margin-top-4xl--only-small {
        margin-top: 400px;
    }

    .section-margin-bottom-4xl--only-small {
        margin-bottom: 400px;
    }

    .no-bottom-margin--small-only {
        margin-bottom: 0!important;
    }

    .no-top-margin--small-only {
        margin-top: 0!important;
    }

    .tiny-bottom-margin--small-only {
        margin-bottom: 1em!important;
    }

    .tiny-top-margin--small-only {
        margin-top: 1em!important;
    }

    .no-width-on-small {
        max-width: unset;
    }
}

/*---------------------
|    GENERIC HELPERS  |
---------------------*/

.grid-container {
    &.fluid {
        padding-inline: 20px;
        &.full-on-small {
            @include breakpoint(small only) {
                padding-inline: 0;
            }
        }
        @include breakpoint (medium) {
            padding-inline: 40px;
        }
        @include breakpoint (xxlarge) {
            padding-inline: 60px;
        }
    }
}

.extra-top-margin-s {
    margin-top: 45px;
}

.article-image {
    display: block;
    width: 180px;
    object-fit: contain;
    aspect-ratio: 3/2;
    margin-bottom: 0px;
    @include breakpoint(medium) {
        display: block;
        width: 100%;
        margin-bottom: 17px;
        aspect-ratio: 3/2;
        height: 100%;
    }
    &.featured {
        object-fit: cover;
        min-height: auto;
        max-height: 100%;
    }
}

.article-card {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    @include breakpoint(medium) {
        flex-direction: column;
    }
    header {
        a {
            display: inline-block;
        }
        @include breakpoint(small only) {
            flex: 0 0 auto;
        }
        a {
            display: block;
        }
    }
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include breakpoint(xlarge) {
            flex: 1 0 auto;
        }
    }
}

.infoIcon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba($color: $body-font-color, $alpha: .38);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
}

.section-posts {
    margin-top: 50px;
    @include breakpoint(xlarge) {
        margin-top: 150px;
    }
    @include breakpoint(xxlarge) {
        margin-top: 150px;
    }
}

article {
    main {
        p, ul, ol {
            @extend .cont-l;
            @extend .no-width-on-small;
            &:not(.intro) {
                @extend .typ-5;
            }
        }
    }
}

.openedOffcanvas {
    overflow-y: hidden;
}

.underlined {
    text-decoration: underline;
}

/*---------------------
|       RESETS        |
---------------------*/
html, body {
    overflow-x: hidden;
    // height: 100%;
}

hr {
    margin: 0;
    max-width: 100%;
    border-color: #efefef;
}

.no-p-margin {
    margin-bottom: 0!important;
}

.no-padding-right {
    padding-right: 0!important;
}

.no-overflow-x {
    overflow-x: hidden;
}

address {
    font-style: normal;
    @include breakpoint(large) {
        white-space: nowrap;
    }
}

.no-events {
    pointer-events: none;
}

/*---------------------
|        FORMS        |
---------------------*/

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 9.5px 0;
    @extend .typ-4-5;
    color: $white;
    margin-bottom: 0;
    border-bottom: 1px solid rgba($color: $white, $alpha: .38);
    footer & {
        border-bottom: 1px solid rgba($color: $white, $alpha: .16);
    }
    .main-form & {
        border-top:1px solid rgba($color: $body-font-color, $alpha: .14);
        color: $body-font-color;
    }
    &:placeholder {
        color: rgba($color: $white, $alpha: .38);
    }
    &:focus {
        background-color: transparent;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid rgba($color: $white, $alpha: 1);
        .main-form & {
            border-top:1px solid rgba($color: $body-font-color, $alpha: 1);
        }
    }
}

textarea[rows] {
    height: 50px;
}

[type="submit"] {
    cursor: pointer;
    flex-basis: fit-content!important;
    width: fit-content!important;
}

label[for="document"] {
    display: flex;
    align-items: baseline;
    column-gap: 10px;
    padding: 9.5px 0;
    border-top: 1px solid rgba($color: $body-font-color, $alpha: .14);
    cursor: pointer;
    @include breakpoint(small only) {
        flex-direction: column;
        row-gap: 10px;
    }
}

[type="file"] {
    cursor: pointer;
    @include breakpoint(small only) {
        flex: 0 0 auto!important;
        margin-left: -5px;
    }
}

input::file-selector-button {
    border: none;
    background-image: url('../../img/Clip\ Icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    text-indent: -99999;
    color: transparent;
    width: 29px;
    height: 29px;
    @include breakpoint(small only) {
        margin-left: -6px;
    }
}

/*---------------------
|        VIDEO        |
---------------------*/

.iframe-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.of-cover {
    object-fit: cover;
}

/*---------------------
|        FIXES        |
---------------------*/

.is-at-bottom {
    top: 0 !important;
  }


  /* CONTACT FORM ERROR */

.wpcf7-not-valid-tip {
  font-size: 14px;
}

.wpcf7 form .wpcf7-response-output {
    font-size: 15px;
}

/* CODE FOR FOOTER HS FORM */

#hsForm_621bf11d-25b9-4b35-b4e2-a58a43fe58f4 {
    display: grid;
    grid-template-areas: "input submit"
                         "paragraph paragraph";
    grid-template-columns: auto min-content;
    grid-template-rows: auto auto;
    column-gap: 30px;
    row-gap: 30px;
    .hs_email {
        grid-area: input;
    }
    .legal-consent-container {
        grid-area: paragraph;
    }
    .hs_submit  {
        grid-area: submit;
    }
    .hs-error-msg, .hs-error-msgs.hs-main-font-element {
        @extend .typ-7;
        color: $white;
        padding-top: 16px;
    }
    
    input[type="submit"] {
        @extend .cta_button;
        @extend .main;
        @extend .light;
        @extend .small;
        @extend .typ-8;
        @extend .typ-medium;
        @extend .typ-lh-regular;
    }
}


#hsForm_c7d7a8cd-4c63-4106-b5e1-08f4c25819bc {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    input[type="submit"] {
        @extend .cta_button;
        @extend .main;
        @extend .light-alt;
        @extend .small;
        @extend .typ-8;
        @extend .typ-medium;
        @extend .typ-lh-regular;
    }
    .legal-consent-container {
        .hs-richtext {
            @extend .typ-7;
        }
    }
}

