.cta_button {
    display: inline-block;
    width: fit-content;
    &.main {
        padding: 8.5px 32.5px;
        background-color: $absolute_zero;
        border-radius: 27px;
        color: $white;
        line-height: 1rem;
        @include breakpoint(xxlarge) {
            padding: 12px 50px;
        }
        &.small {
            padding: 9.5px 27.5px;
        }
        &.tiny {
            padding: 9.5px 24px;
        }
        &.light {
            color: rgba($color: $white, $alpha: .38);
            background-color: transparent;
            border: 1px solid rgba($color: $white, $alpha: .38);
            transition: color .25s ease, background-color .25s ease, border-color .25s ease;
            &:hover {
                background-color: $white;
                color: $body-font-color;
                border-color: $white;
            }
        }
        &.light-alt {
            color: rgba($color: $body-font-color, $alpha: .38);
            background-color: transparent;
            border: 1px solid rgba($color: $body-font-color, $alpha: .38);
            transition: color .25s ease, background-color .25s ease, border-color .25s ease;
            &:hover {
                background-color: $absolute_zero;
                color: $white;
                border-color: $absolute_zero;
            }
        }
    }
    &.simple {
        @extend .typ-6;
        color: $body-font-color;
        opacity: .6;
        transition: opacity .25s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.has-image {
            opacity: 1;
            img {
                margin-right: 30px;
            }
            .wording {
                transition: opacity .25s ease;
                opacity: .6;
            }
        }
        &:after {
            content: '';
            padding-left: 10px;
            width: 9px;
            height: 13px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iIzAxMWMzYyIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
            background-position: center right;
            background-repeat: no-repeat;
            box-sizing: content-box;
            transition: transform .25s ease;
        }
        &.download {
            // opacity: 1;
            font-weight: 500;
        }
        &.download-icon {
            &:after {
                content: '';
                padding-left: 10px;
                width: 14px;
                height: 12px;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='55.5 394.9 14.1 11.3' style='enable-background:new 55.5 394.9 14.1 11.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23011C3C;%7D%0A%3C/style%3E%3Cg id='Download_CTA_Desktop' transform='translate(1)'%3E%3Cpath id='Icon_ionic-md-arrow-forward' class='st0' d='M55.7,394.9l-1.2,1.3l7,7l7.1-7l-1.2-1.3l-5.8,5.7L55.7,394.9z'/%3E%3Crect id='Rectangle_1539' x='54.5' y='404.5' class='st0' width='14.1' height='1.7'/%3E%3C/g%3E%3C/svg%3E");                background-position: center right;
                background-repeat: no-repeat;
                box-sizing: content-box;
                // transition: transform .25s ease;
            }
        }
        &:hover {
            opacity: 1;
            &:after {
                transform: translateX(5px);
            }
            &.has-image {
                .wording {
                    opacity: 1;
                }
            }
            &.download-icon {
                &:after {
                    transform: none;
                }
            }
        }
        &.light {
            color: $white;
            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iI2ZmZiIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
            }
        }
        &.back {
            &::after {
                display: none;
            }
            &:before {
                content: '';
                padding-left: 10px;
                width: 9px;
                height: 13px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iIzAxMWMzYyIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
                background-position: center right;
                background-repeat: no-repeat;
                box-sizing: content-box;
                transform: rotateZ(180deg);
                transition: transform .25s ease;
            }
            &.light {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iI2ZmZiIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
                }
            }
            &:hover {
                opacity: 1;
                &:before {
                    transform: translateX(-5px) rotateZ(180deg);
                }
            }
        }
    }
    &.footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &:after {
            content: '';
            width: 8.22px;
            height: 12.84px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iIzAxMWMzYyIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
        }
        &.light {
            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjIyNSIgaGVpZ2h0PSIxMi44NCIgdmlld0JveD0iMCAwIDguMjI1IDEyLjg0Ij4KICA8cGF0aCBpZD0iSWNvbl9pb25pYy1tZC1hcnJvdy1mb3J3YXJkIiBkYXRhLW5hbWU9Ikljb24gaW9uaWMtbWQtYXJyb3ctZm9yd2FyZCIgZD0iTTkuMTUsMTcuNjkzbDEuMjYyLDEuMTIzLDYuOTYyLTYuNDItNi45NjItNi40Mkw5LjE5NCw3LjFsNS42NDcsNS4zWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMTUgLTUuOTc3KSIgZmlsbD0iI2ZmZiIgb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=");
            }
        }
    }
    &.centered {
        margin-inline: auto;
    }
}